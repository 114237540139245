import "./Newsletter.scss";
import navIcon2 from '../../assets/img/base/message.svg';

export const Newsletter = ({}) => {
  return (
    <div className="newsletter-bx" id="coop">
      <h4>联系方式</h4>
      <br></br>
        <div className="new-email-bx">
          <span>hello@imrains.com</span>

          <div className="contact-icon"><a href="mailto:imrains.cn@gmail.com"><img src={navIcon2} alt="" /></a></div>
        </div>
    </div>
  );
};
