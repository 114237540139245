import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar/NavBar";
import { Banner } from "./components/Banner/Banner";
import { Blogs } from "./components/Blogs/Blogs";
import { Tutorial } from "./components/Tutorial/Tutorial";
import { Contact } from "./components/Contact/Contact";
import { Footer } from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Blogs />
      <Tutorial />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
