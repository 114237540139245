import "./Blogs.scss";
import { CaretRightFill, ChatRightQuote } from "react-bootstrap-icons";

export const Blogs = () => {
  return (
    <section className="blog" id="blog">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="blog-bx wow zoomIn">
              <h2>文字教程</h2>
              <div className="blog-msg">
                <p>
                  通过文字，分享一些我认为有价值的游戏开发经验，涵盖从项目规划、场景设计到代码优化等各个方面。通过我的视角，让开发者将更全面地了解使用Godot引擎。
                </p>
                <p><ChatRightQuote size={18} style={{marginRight:8}}></ChatRightQuote>目前知识库正处于建设状态，文章数量并不会特别多，如果你也有文章愿意分享，请联系我。</p>
              </div>
              <div className="button-container">
                <button onClick={() => window.location = "http://doc.imrains.com"}>
                  前往知识库 <CaretRightFill size={16} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
