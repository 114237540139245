import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../../assets/img/base/header-img.svg";
import bgImg from "../../assets/img/base/Space.svg"
import "animate.css";
import TrackVisibility from "react-on-screen";
import "./Banner.scss";

export const Banner = () => {
  return (
    <section className="banner" id="intro">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) => (
                
                <div
                  className={
                    isVisible ? "animate__animated animate__pulse" : ""
                  }
                >
                  <span className="tagline">ImRains</span>
                  <h1>
                    {`Game Studio`}
                  </h1>
                  <p>
                    欢迎来到<span style={{color:'#fff'}}> Rain </span>的游戏开发网站<br/>
                    网站主要致力于分享和总结有关于 Godot 游戏开发经验与技术见解<br/>
                    通过项目实践和学习总结，分享在 Godot 中实现游戏开发过程中的技术难题、优化策略、最佳实践等方面的经验。
                  </p>
                </div>
              )}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            {/* <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={`poster-container ${isVisible ? "animate__animated animate__pulse" : ""}`}
                >
                  <img className="poster-img" src={headerImg} alt="Header Img" />
                  <img className="poster-bg" src={bgImg} alt="Footer Img" />
                  <img className="poster-con" src={bgImg} alt="Footer Img" />
                </div>
              )}
            </TrackVisibility> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
