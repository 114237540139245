import { Container, Row, Col } from "react-bootstrap";
import logo from "../../assets/img/base/logo.png";
import navIcon1 from "../../assets/img/base/bili.svg";
import navIcon2 from "../../assets/img/base/message.svg";
import "./Footer.scss";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a
                href="https://space.bilibili.com/66079515"
                target="_blank"
                rel="noreferrer"
              >
                <img src={navIcon1} alt="" />
              </a>
              <a href="mailto:imrains.cn@gmail.com">
                <img src={navIcon2} alt="" />
              </a>
            </div>
            <p>
              ImRains 2024 |{" "}
              <a
                className="link-style"
                href="https://beian.miit.gov.cn/"
                target="_blank"
                rel="noreferrer"
              >
                豫ICP备2022012680号-1
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
