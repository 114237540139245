import { Col } from "react-bootstrap";
import "./TutorialCard.scss";

export const TutorialCard = ({ title, description, imgUrl, jumpUrl }) => {
 const clickCard = ()=>{
  window.open(jumpUrl, '_blank')
 }

  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-container" onClick={()=>clickCard()}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt="图片"/>
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
        </div>
      </div>
      </div>
    </Col>
  )
}
