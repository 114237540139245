import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { TutorialCard } from "../TutorialCard/TutorialCard";
import "animate.css";
import TrackVisibility from "react-on-screen";
import "./Tutorial.scss";

export const Tutorial = () => {
  const tutorials1 = [
    {
      title: "观看视频",
      description: "将前往bilibili网站",
      imgUrl: "https://cdn.jsdelivr.net/gh/ImRains/imrains-img-cloud/docs/202401111343443.png",
      jumpUrl:"https://www.bilibili.com/video/BV1zG411v7Fh"
    },
    {
      title: "观看视频",
      description: "将前往bilibili网站",
      imgUrl: "https://cdn.jsdelivr.net/gh/ImRains/imrains-img-cloud/docs/202401111343454.png",
      jumpUrl:"https://www.bilibili.com/video/BV14i4y1v7Em"
    },
    {
      title: "观看视频",
      description: "将前往bilibili网站",
      imgUrl: "https://cdn.jsdelivr.net/gh/ImRains/imrains-img-cloud/docs/202401111343464.png",
      jumpUrl:"https://www.bilibili.com/video/BV1Tu4y1H7T3"
    },
    {
      title: "观看视频",
      description: "将前往bilibili网站",
      imgUrl: "https://cdn.jsdelivr.net/gh/ImRains/imrains-img-cloud/docs/202401111343472.png",
      jumpUrl:"https://www.bilibili.com/video/BV1fi4y187LS"
    },
    {
      ttitle: "观看视频",
      description: "将前往bilibili网站",
      imgUrl: "https://cdn.jsdelivr.net/gh/ImRains/imrains-img-cloud/docs/202401111343481.png",
      jumpUrl:"https://www.bilibili.com/video/BV1Cj411H7v1"
    },
    {
      title: "观看视频",
      description: "将前往bilibili网站",
      imgUrl: "https://cdn.jsdelivr.net/gh/ImRains/imrains-img-cloud/docs/202401111343490.png",
      jumpUrl:"https://www.bilibili.com/video/BV1de411i7Lh"
    },
  ];

  const tutorials2 = [
    {
      title: "观看视频",
      description: "将前往bilibili网站",
      imgUrl: "https://cdn.jsdelivr.net/gh/ImRains/imrains-img-cloud/docs/202401111343424.png",
      jumpUrl:"https://www.bilibili.com/video/BV1Kc411X7E9"
    },
    {
      title: "观看视频",
      description: "将前往bilibili网站",
      imgUrl: "https://cdn.jsdelivr.net/gh/ImRains/imrains-img-cloud/docs/202401111343415.png",
      jumpUrl:"https://www.bilibili.com/video/BV1SN411M7sA"
    },
    {
      title: "观看视频",
      description: "将前往bilibili网站",
      imgUrl: "https://cdn.jsdelivr.net/gh/ImRains/imrains-img-cloud/docs/202401111343404.png",
      jumpUrl:"https://www.bilibili.com/video/BV1FC4y1H7vn"
    },
    {
      title: "观看视频",
      description: "将前往bilibili网站",
      imgUrl: "https://cdn.jsdelivr.net/gh/ImRains/imrains-img-cloud/docs/202401111343393.png",
      jumpUrl:"https://www.bilibili.com/video/BV19u4y1a746"
    },
    {
      ttitle: "观看视频",
      description: "将前往bilibili网站",
      imgUrl: "https://cdn.jsdelivr.net/gh/ImRains/imrains-img-cloud/docs/202401111343379.png",
      jumpUrl:"https://www.bilibili.com/video/BV18e411R7sV"
    },
    {
      title: "观看视频",
      description: "将前往bilibili网站",
      imgUrl: "https://cdn.jsdelivr.net/gh/ImRains/imrains-img-cloud/docs/202401111343351.png",
      jumpUrl:"https://www.bilibili.com/video/BV1np4y1M7CZ"
    },
  ];

  const tutorials3 = [
    {
      title: "观看视频",
      description: "将前往bilibili网站",
      imgUrl: "https://cdn.jsdelivr.net/gh/ImRains/imrains-img-cloud/docs/202401111343434.png",
      jumpUrl:"https://www.bilibili.com/video/BV1aH4y167Lv"
    }
  ];

  return (
    <section className="tutorial" id="video">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "" : ""}>
                  <h2>视频教程</h2>
                  <div className="video-msg">
                  <p>
                    除去文字分享以外，我会不定期更新关于游戏开发的教程视频。
                  </p>
                  <p>
                  视频教程通过图像和声音的结合，提供了更加生动、直观的学习体验。学习者可以通过观看实际操作、演示和听取解释，更容易理解复杂的概念和流程。
                  </p>
                  </div>
                  <Tab.Container id="tutorials-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">系列教程</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">入门教程</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">进阶教程</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {tutorials1.map((tutorial, index) => {
                            return <TutorialCard key={index} {...tutorial} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {tutorials2.map((tutorial, index) => {
                            return <TutorialCard key={index} {...tutorial} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          {tutorials3.map((tutorial, index) => {
                            return <TutorialCard key={index} {...tutorial} />;
                          })}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
