import { Container, Row, Col } from "react-bootstrap";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { Newsletter } from "../Newsletter/Newsletter";
import "./Conect.scss";

export const Contact = () => {

  return (
    <section className={`contact`} id="connect">
      <Container>
        <TrackVisibility>
          {({ isVisible }) => (
            <div
              className={isVisible ? "" : ""}
            >
              <Row className="align-items-center">
                <Col size={12} md={6}>
                  <img src="https://cdn.jsdelivr.net/gh/ImRains/imrains-img-cloud/docs/202401111406105.svg"/>
                </Col>
                <Col size={12} md={6}>
                  <div className="coop-container">
                    <Newsletter
                        status={"1"}
                        message={"message"}
                        />
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </TrackVisibility>
      </Container>
    </section>
  );
};
