import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../../assets/img/base/logo.png';
import navIcon1 from '../../assets/img/base/bili.svg';
import navIcon2 from '../../assets/img/base/message.svg';
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";
import "./NavBar.scss";

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Router>
      <Navbar expand="md" className={`navbar-container ${scrolled ? "scrolled" : ""}`}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto" style={{cursor:"point"}}>
              <Nav.Link href="#intro" className={activeLink === 'intro' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('intro')}>介绍 | Intro</Nav.Link>
              <Nav.Link href="#blog" className={activeLink === 'blog' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('blog')}>技术 | Blog</Nav.Link>
              <Nav.Link href="#video" className={activeLink === 'video' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('video')}>教程 | Video</Nav.Link>
              <Nav.Link href="#coop" className={activeLink === 'coop' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('coop')}>合作 | Coop</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://space.bilibili.com/66079515" target="_blank" rel="noreferrer"><img src={navIcon1} alt="" /></a>
                <a href="mailto:imrains.cn@gmail.com"><img src={navIcon2} alt="" /></a>
              </div>
              {/* <HashLink to='#connect'>
                <button><span>开始</span></button>
              </HashLink> */}
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}
